<template>
  <div class="allmain">
    <div class="header">
      <div class="header_logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="header_center">
        <div @click="gohome">首页</div>
        <div @click="goproductIntroduction">产品介绍</div>
        <div style="color: #2558e4" @click="gocompanyprofile">公司简介</div>
        <!-- <div @click="gopayorder">立即购买</div> -->
      </div>
      <div class="header_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <img src="../../assets/company_1.png" />
    </div>
    <div class="main_title">
      <div class="magin_titleData">公司简介</div>
      <div class="companyText">
        <div>长沙分群大数据有限公司</div>
        <div>Changsha Group Big Data Co.LTD</div>
        <div>
          长沙分群大数据有限公司（简称“分群数据”）为线下门店提供客群数据服务，分群数据把研发、营销总部，放置在离客户最近的一线，总部落户在长沙。<br />
          传统零售以商品为中心的经营模式深陷三高一低（低来客、高客单、高毛利、高营销费用）行业困境，分群数据首创顾客分群算法，通过过店、进店和成交客群的洞察分析，
          帮助门店构建以顾客为中心的零售新生态，改变线下门店生意难做的局面。<br />
          分群数据以分群创造价值为经营理念，秉承务实、创造、共生和坚韧的价值观，力争成为世界领先的客群数据服务公司。
        </div>
      </div>
    </div>
    <div class="main_case">
      <div class="main_case_bg">
        <div class="magin_titleData">分群创造价值</div>
        <div class="main_cardList">
          <div class="main_card_one">
            <div class="main_card_text">
              <div>使命</div>
              <div>构建以顾客为中心的零售新生态！</div>
            </div>
          </div>
          <div class="main_card_two">
            <div class="main_card_text">
              <div>愿景</div>
              <div>成为世界领先的客群数据公司！</div>
            </div>
          </div>
          <div class="main_card_three">
            <div class="main_card_text">
              <div>价值观</div>
              <div>务实——勤勉、朴实、经世致用</div>
              <div>创造——创新、首创、追求卓越</div>
              <div>共生——开放、分享、包容利他</div>
              <div>坚韧——谦虚、执着、百折不挠</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main_bottom">
      <div class="main_bottom_bg">
        <div class="main_bottom_apply">
          <div>
            <img src="../../assets/apply.png" />
          </div>
          <div>
            <span>填写入驻申请，专人联系为您服务！</span>
          </div>
          <div>
            <img src="../../assets/code.png" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_logo">
        <img src="../../assets/fqsj_2.png" />
      </div>
      <div class="footer_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat_on.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone_on.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023024436号</a>
      </div>
      <div>联系地址：开福区秀峰街道秀峰商贸城12栋2号101</div>
    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {};
  },
  methods: {
    goDetail() {
      // 在 Vue 实例或组件中调用
      this.$router.push({ name: "caseDetail" });
    },
    goApply() {
      this.$router.push({ name: "applicationSettlement" });
    },
    gohome() {
      this.$router.push({ name: "home" });
    },
    goproductIntroduction() {
      this.$router.push({ name: "productIntroduction" });
    },
    gocompanyprofile() {
      this.$router.push({ name: "companyprofile" });
    },
    gopayorder() {
      this.$router.push({ name: "payOrder" });
    },
  },
};
</script>
    
    <style scoped>
.allmain {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.header {
  height: 100px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_right {
  display: flex;
  align-items: center;
}
.header_right div {
  margin: 0 10px;
}
.header_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.header_center {
  width: 50%;
  display: flex;
}
.header_center div {
  margin: 0px 50px;
}
.main img {
  width: 100%;
}
.main_title {
  text-align: center;
  margin: 120px 15%;
}
.main_cardList {
  display: flex;
}
.main_card_one {
  text-align: left;
  width: 33%;
  margin: 10px;
  height: 420px;
  background: url("../../assets/company_2.png") no-repeat;
  background-size: 100% 100%;
}

.main_card_two {
  text-align: left;
  width: 33%;
  margin: 10px;
  height: 420px;
  background: url("../../assets/company_3.png") no-repeat;
  background-size: 100% 100%;
}

.main_card_three {
  text-align: left;
  width: 33%;
  margin: 10px;
  height: 420px;
  background: url("../../assets/company_4.png") no-repeat;
  background-size: 100% 100%;
}
.main_card div:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 20px;
}

.main_card div:nth-child(3) {
  font-size: 14px;
  color: #777777;
  text-align: left;
}
.magin_titleData {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 80px;
}
.main_case {
  width: 100%;
  background: url("../../assets/case_1.png") no-repeat;
  background-size: 100% 100%;
}
.main_case_bg {
  text-align: center;
  padding: 50px 15%;
}
.main_bottom {
  width: 100%;
  background: url("../../assets/FQSJ_logo.png") no-repeat;
  background-size: 100% 100%;
}
.main_bottom_bg {
  text-align: center;
  height: 320px;
}
.main_bottom_apply div:nth-child(1) {
  padding: 80px 0px 20px 0px;
}
.main_bottom_apply div:nth-child(2) {
  color: #777777;
  font-size: 14px;
  padding-bottom: 20px;
}
.main_bottom_apply .el-button {
  width: 160px;
  height: 42px;
  color: white;
  background-color: #2558e4;
  border-radius: 10px;
}
.footer {
  background-color: #111111;
  color: white;
  height: 100px;
  padding: 0px 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_right {
  display: flex;
  align-items: center;
}
.footer_right div {
  margin: 0 10px;
}
.footer_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.record {
  height: 80px;
  color: white;
  padding: 0 10%;
  background-color: #3d3d3d;
  width: 80%;
  line-height: 40px;
}
.record div {
  margin: 0px 10px;
}
.record a {
  color: white;
  text-decoration: none;
}
.companyText {
  text-align: left;
  line-height: 36px;
}
.companyText div:nth-child(1) {
  font-size: 36px;
  font-weight: 700;
}
.companyText div:nth-child(2) {
  margin-top: 10px;
  letter-spacing: 4px;
  font-size: 18px;
  font-weight: 400;
}

.companyText div:nth-child(3) {
  color: #333333;
  font-size: 18px;
}
.main_card_text {
    color: #333333;
  margin: 50px 40px;
  line-height: 50px;
}
.main_card_text div:nth-child(1) {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
}
</style>
    