var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allmain"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"header_center"},[_c('div',{on:{"click":_vm.gohome}},[_vm._v("首页")]),_c('div',{on:{"click":_vm.goproductIntroduction}},[_vm._v("产品介绍")]),_c('div',{staticStyle:{"color":"#2558e4"},on:{"click":_vm.gocompanyprofile}},[_vm._v("公司简介")])]),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_logo"},[_c('img',{attrs:{"src":require("../../assets/logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_right"},[_c('div',[_c('span',[_vm._v("联系我们")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/wechatcode.png")}})]),_c('div',[_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/wechat.png")}}),_c('div',[_vm._v("fenqunshuju")])]),_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/phone.png")}}),_c('div',[_vm._v("19967004532")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('img',{attrs:{"src":require("../../assets/company_1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_title"},[_c('div',{staticClass:"magin_titleData"},[_vm._v("公司简介")]),_c('div',{staticClass:"companyText"},[_c('div',[_vm._v("长沙分群大数据有限公司")]),_c('div',[_vm._v("Changsha Group Big Data Co.LTD")]),_c('div',[_vm._v(" 长沙分群大数据有限公司（简称“分群数据”）为线下门店提供客群数据服务，分群数据把研发、营销总部，放置在离客户最近的一线，总部落户在长沙。"),_c('br'),_vm._v(" 传统零售以商品为中心的经营模式深陷三高一低（低来客、高客单、高毛利、高营销费用）行业困境，分群数据首创顾客分群算法，通过过店、进店和成交客群的洞察分析， 帮助门店构建以顾客为中心的零售新生态，改变线下门店生意难做的局面。"),_c('br'),_vm._v(" 分群数据以分群创造价值为经营理念，秉承务实、创造、共生和坚韧的价值观，力争成为世界领先的客群数据服务公司。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_case"},[_c('div',{staticClass:"main_case_bg"},[_c('div',{staticClass:"magin_titleData"},[_vm._v("分群创造价值")]),_c('div',{staticClass:"main_cardList"},[_c('div',{staticClass:"main_card_one"},[_c('div',{staticClass:"main_card_text"},[_c('div',[_vm._v("使命")]),_c('div',[_vm._v("构建以顾客为中心的零售新生态！")])])]),_c('div',{staticClass:"main_card_two"},[_c('div',{staticClass:"main_card_text"},[_c('div',[_vm._v("愿景")]),_c('div',[_vm._v("成为世界领先的客群数据公司！")])])]),_c('div',{staticClass:"main_card_three"},[_c('div',{staticClass:"main_card_text"},[_c('div',[_vm._v("价值观")]),_c('div',[_vm._v("务实——勤勉、朴实、经世致用")]),_c('div',[_vm._v("创造——创新、首创、追求卓越")]),_c('div',[_vm._v("共生——开放、分享、包容利他")]),_c('div',[_vm._v("坚韧——谦虚、执着、百折不挠")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_bottom"},[_c('div',{staticClass:"main_bottom_bg"},[_c('div',{staticClass:"main_bottom_apply"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/apply.png")}})]),_c('div',[_c('span',[_vm._v("填写入驻申请，专人联系为您服务！")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/code.png")}})]),_c('div')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_logo"},[_c('img',{attrs:{"src":require("../../assets/fqsj_2.png")}})]),_c('div',{staticClass:"footer_right"},[_c('div',[_c('span',[_vm._v("联系我们")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/wechatcode.png")}})]),_c('div',[_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/wechat_on.png")}}),_c('div',[_vm._v("fenqunshuju")])]),_c('div',{staticClass:"header_right_wechat"},[_c('img',{attrs:{"src":require("../../assets/phone_on.png")}}),_c('div',[_vm._v("19967004532")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"record"},[_c('div',[_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("湘ICP备2023024436号")])]),_c('div',[_vm._v("联系地址：开福区秀峰街道秀峰商贸城12栋2号101")])])
}]

export { render, staticRenderFns }